import React from 'react'
import { Image, Text, Box, Container } from 'theme-ui'
import { Global } from '@emotion/react'

import Section from '@components/Section'
import NavigationFooter from '@components/Navigation/Navigation.Footer'
import NavigationHeader from '@components/Navigation/Navigation.Header'
import { globalStyles } from '@styles'

import ArticlesList from '../sections/articles/Articles.List'
import Paginator from '@components/Navigation/Navigation.Paginator'
import { ArticlesPaginator } from './articles.template'
import ArticlesHero from '../sections/articles/Articles.Hero'
import ArticlesContextProvider from '../sections/articles/Articles.List.Context'

const CountryPage = ({ location, pageContext }): void => {
  const articles = pageContext.group
  const authors = pageContext.additionalContext.authors
  const image = pageContext.additionalContext.image
  const countryLabel = pageContext.additionalContext.countryLabel

  return (
    <Container>
      <Global styles={globalStyles} />
      <NavigationHeader />
      <FullScreenImageContainer
        imageSrc={image.large.src}
        countryLabel={countryLabel}
      />
      <Section>
        {/* Navigation.Header 98px, HorizontalMenu topPadding 48px */}
        <Box sx={{ marginTop: 'calc(100vh - 98px + 48px)' }} />
        <ArticlesContextProvider>
          <ArticlesHero authors={authors} />
          <ArticlesList articles={articles} />
          <ArticlesPaginator show={pageContext.pageCount > 1}>
            <Paginator {...pageContext} />
          </ArticlesPaginator>
        </ArticlesContextProvider>
      </Section>
      <NavigationFooter />
    </Container>
  )
}

export default CountryPage

const FullScreenImageContainer = ({ imageSrc, countryLabel }): void => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          padding: '5rem',
          justifySelf: 'flex-end',
          background: 'linear-gradient(0deg,rgba(0,0,0,0.88) 0%,transparent 88%)',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Text sx={{ color: 'white', fontSize: '30px' }}>{countryLabel}</Text>
      </Box>
      <Image
        sx={{ objectFit: 'cover', height: '100%', width: '100%' }}
        src={imageSrc}
      />
    </Box>
  )
}
